import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDhLvFNzB7Mp7OAcd47Wp-emPkglpmONzY",
  authDomain: "cucas-fa428.firebaseapp.com",
  projectId: "cucas-fa428",
  storageBucket: "cucas-fa428.appspot.com",
  messagingSenderId: "201014493827",
  appId: "1:201014493827:web:61c155665a1ae8595651b6",
  measurementId: "G-Q6EC8489YL"
};


// Initialize Firebase and Firestore
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
export { db }