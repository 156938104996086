import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Row, Col, Button } from 'react-bootstrap';
import './form.css';
import { collection , onSnapshot, serverTimestamp, addDoc } from 'firebase/firestore';
import {db} from './firebase'

export default function FormComponent() {
    const [form, setFormData] = useState<any>({
        agentName: "",
        agentNumber: "",
        accountName: "",
        accountNumber: "",
        location: ""
    });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    
    const handleInput = (name: any, event: any) => {
        setFormData({
            ...form,
            [name]: event.target.value 
        })
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        addDoc(collection(db, 'workers'), form);
        console.log(form)
        setFormData({
            agentName: "",
            agentNumber: "",
            accountName: "",
            accountNumber: "",
            location: ""
        })
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }


  return (
    <>
        <Form className="form" onSubmit={handleSubmit}> 
        {showAlert && 
            <div className="alert-container">
                <span className="alert-text">Information saved successfully</span>
            </div>
        }
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Agent Name</Form.Label>
                    <Form.Control value={form.agentName} type="name" placeholder="Enter agent Name" onChange={(event) => handleInput("agentName", event)} />
                </Form.Group>
            </Row>

            <Form.Group as={Col} className="mt-2" controlId="formGridEmail">
                <Form.Label>Agent Number</Form.Label>
                <Form.Control value={form.agentNumber} type="text" placeholder="Enter agent Number" onChange={(event) => handleInput("agentNumber", event)} />
             </Form.Group>

            <Form.Group as={Col} className="mt-2" controlId="formGridEmail">
                <Form.Label>Afriland Account Name</Form.Label>
                <Form.Control value={form.accountName} type="text" placeholder="Enter account Name" onChange={(event) => handleInput("accountName", event)} />
            </Form.Group>

            <Form.Group className="mb-3 mt-2" controlId="formGridAddress1">
                <Form.Label>Afriland Account Number</Form.Label>
                <Form.Control value={form.accountNumber} placeholder="xxxxxxxxx" type='text' onChange={(event) => handleInput("accountNumber", event)}/>
            </Form.Group>

            <Form.Group className="mb-3 mt-2" controlId="formGridAddress2">
                <Form.Label>Kiosk Location</Form.Label>
                <Form.Control value={form.location} placeholder="Enter Kiosk location"onChange={(event) => handleInput("location", event)} />
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    </>
  )
}
